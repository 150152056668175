<ao-layout-base>
  <ng-template ao-layout-base-meta>
    <account-toast-container [loggedOut]="applicationState === 'loggedOut'"></account-toast-container>
  </ng-template>

  <ng-template ao-layout-base-content>
    <div *ngIf="applicationState !== 'initial'" class="a_account" [ngStyle]="{ '--sw-container-width': customWidth }">
      <div class="topbar-wrapper">
        <account-browser-compatibility-notice></account-browser-compatibility-notice>
      </div>

      <ng-container *ngIf="applicationState === 'loggedIn'; else blackOverlay">
        <account-navigation></account-navigation>
        <div class="a_content" accountScrollingElement>
          @if (isActionBarVisible) {
            <account-action-bar></account-action-bar>
          }
          <account-banned-banner
            *ngIf="isBanned$ | async"
            [isBanned]="isBanned$ | async"
            [isSoftBanned]="isSoftBanned$ | async"></account-banned-banner>
          <ng-container *ngTemplateOutlet="routerOutlet"></ng-container>
        </div>
      </ng-container>

      <ng-template #blackOverlay>
        <div class="a_content black-overlay" accountScrollingElement>
          <ng-container *ngTemplateOutlet="routerOutlet"></ng-container>
        </div>
      </ng-template>

      <ng-template #routerOutlet>
        <div [ngClass]="{ 'a_content-inner dnd-boundary': true, 'route-fullscreen': isRouteFullscreen }">
          <div [ngClass]="{ 'a_container route-container': true, 'route-fullscreen': isRouteFullscreen }">
            <div class="route-container" [hidden]="logoutInProgress$ | async">
              <router-outlet></router-outlet>
            </div>
            <router-outlet name="modal"></router-outlet>
          </div>
          <account-footer></account-footer>
        </div>
      </ng-template>
    </div>
  </ng-template>
</ao-layout-base>
