import { basketActions, sessionActions } from '@account/core/store/actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { UserAccountsGateway } from '../../../gateways/auth';
import {
  CompanyOnboardingData,
  CreateCompanyWithBillingCountry,
  S3Download,
  SbpException,
  UserAccount,
  UserAccountRegistration,
} from '../../../models';
import { LoggingService } from '../../../services';
import * as companyActions from '../../company/company-meta.actions';
import * as navigationActions from '../../navigation/navigation.actions';
import { RootState } from '../../root.state';
import * as sessionSelectors from '../../session/session.selectors';
import * as invitationsActions from '../invitations/invitations.actions';
import * as membershipsActions from '../memberships/memberships.actions';
import * as permissionActions from '../permissions/permissions.actions';
import * as actions from './useraccount.actions';

@Injectable({
  providedIn: 'root',
})
export class UseraccountEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly userAccountsGateway: UserAccountsGateway,
  ) {}

  createUserAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createUserAccount),
      map((action) => action.payload),
      switchMap((registrationData: UserAccountRegistration) =>
        this.userAccountsGateway.createUserAccount(registrationData).pipe(
          map((userAccount: UserAccount) => actions.createUserAccountSuccess({ payload: userAccount })),
          catchError((error: SbpException) => of(actions.createUserAccountFail({ payload: error })))
        )
      )
    )
  );

  getUserAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getUserAccount),
      map((action) => action.payload.id),
      switchMap((userAccountId: number) =>
        this.userAccountsGateway.getUserAccount(userAccountId).pipe(
          tap((userAccount: UserAccount) => {
            LoggingService.setUserAccountContextForErrorHandler(userAccount);
          }),
          map((userAccount: UserAccount) => actions.getUserAccountSuccess({ payload: userAccount })),
          catchError((error: SbpException) => of(actions.getUserAccountFail({ payload: error }))),
          takeUntil(this.actions$.pipe(ofType(sessionActions.logout)))
        )
      )
    )
  );

  getUserAccountSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getUserAccountSuccess),
      map((action) => action.payload),
      concatMap((userAccount: UserAccount) => {
        const nextActions: Action[] = [
          navigationActions.setNavigationForUserAccount(),
          actions.getAvatar({ payload: { id: userAccount.id } }),
          invitationsActions.getInvitations({ payload: userAccount.id }),
          membershipsActions.getMemberships({ payload: userAccount.id }),
        ];
        if (userAccount.selectedMembership) {
          nextActions.push(companyActions.getCompany({ payload: userAccount.selectedMembership.company.id }));
          nextActions.push(permissionActions.setPermissions({ payload: userAccount.selectedMembership }));
          nextActions.push(basketActions.setBasketForCompanyUserAccount());
        } else {
          nextActions.push(companyActions.noCompanyExists());
        }
        return nextActions;
      })
    )
  );

  updateUserAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateUserAccount),
      map((action) => action.payload),
      switchMap((userAccount: UserAccount) =>
        this.userAccountsGateway.updateUserAccount(userAccount).pipe(
          map((userAccount: UserAccount) => actions.updateUserAccountSuccess({ payload: userAccount })),
          catchError((error: SbpException) => of(actions.updateUserAccountFail({ payload: error })))
        )
      )
    )
  );

  createCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createCompany),
      map((action) => action.payload),
      withLatestFrom(this.store.select(sessionSelectors.getUserAccountId)),
      switchMap(
        ([payload, userAccountId]: [{ company: CreateCompanyWithBillingCountry; approveGtc: boolean }, number]) =>
          this.userAccountsGateway.createCompany(userAccountId, payload.company, payload.approveGtc).pipe(
            concatMap(() => [
              sessionActions.resetCompanyAttributes(),
              companyActions.refreshCompany(),
              companyActions.refreshSignedGTC(),
              actions.getUserAccount({ payload: { id: userAccountId } }),
            ]),
            catchError((error: SbpException) => of(actions.createCompanyFail({ payload: error })))
          )
      )
    )
  );

  createCompanyDuringOnboarding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createCompanyDuringOnboarding),
      map((action) => action.payload),
      withLatestFrom(this.store.select(sessionSelectors.getUserAccountId)),
      switchMap(
        ([payload, userAccountId]: [{ company: CompanyOnboardingData }, number]) =>
          this.userAccountsGateway.createCompanyDuringOnboarding(payload.company).pipe(
            concatMap(() => [
              sessionActions.resetCompanyAttributes(),
              companyActions.refreshCompany(),
              companyActions.refreshSignedGTC(),
              actions.getUserAccount({ payload: { id: userAccountId } }),
              navigationActions.resetNavigation(),
              navigationActions.setNavigationForUserAccount(),
            ]),
            catchError((error: SbpException) => of(actions.createCompanyFail({ payload: error })))
          )
      )
    )
  );

  getAvatar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getAvatar),
      map((action) => action.payload),
      switchMap((payload: { id: number }) =>
        this.userAccountsGateway.getAvatar(payload.id).pipe(
          map((s3download: S3Download) => actions.getAvatarSuccess({ payload: s3download.downloadUrl })),
          catchError((error: SbpException) => of(actions.getAvatarFail({ payload: error })))
        )
      )
    )
  );

  uploadAvatar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.uploadAvatar),
      map((action) => action.payload),
      switchMap((payload: { id: number; file: File }) =>
        this.userAccountsGateway.uploadAvatar(payload.id, payload.file).pipe(
          map((s3download: S3Download) => actions.uploadAvatarSuccess({ payload: s3download.downloadUrl })),
          catchError((error: SbpException) => of(actions.uploadAvatarFail({ payload: error })))
        )
      )
    )
  );
}
