import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';

import { AuthGuard, ProducerGuard, ProfileGuard, TermsGuard } from './core';
import { CompanyOnboardingGuard } from './core/guards/company-onboarding.guard';
import { MigrationGuard } from './core/guards/migration.guard';
import { frontRoutes } from './views/front/front.routes';
import { registerRoutes } from './views/register/register.routes';

const canActivateAuthenticated: CanActivateFn | CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => inject(AuthGuard).canActivate(route, state);

const canActivateProducer: CanActivateFn | CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => inject(ProducerGuard).canActivate(route, state);

const canActivateProfile: CanActivateFn | CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => inject(ProfileGuard).canActivate(route, state);

const canActivateTerms: CanActivateFn | CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => inject(TermsGuard).canActivate(route, state);

const canActivateMigrationCampaign: CanActivateFn | CanActivateChildFn = (route: ActivatedRouteSnapshot) =>
  inject(MigrationGuard).canActivate(route);

const canActivateCompanyOnboarding: CanActivateFn | CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => inject(CompanyOnboardingGuard).canActivate(route, state);

export const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'portal',
  },
  ...frontRoutes,
  ...registerRoutes,
  {
    path: 'portal',
    loadChildren: () => import('./views/portal/portal.routes').then((c) => c.portalRoutes),
    canActivateChild: [canActivateAuthenticated, canActivateMigrationCampaign],
    data: {
      fullscreen: true,
    },
  },
  {
    path: 'company',
    loadChildren: () => import('./views/onboarding/company.routes').then((child) => child.companyRoutes),
    canActivate: [canActivateCompanyOnboarding],
    data: {
      fullscreen: true,
    },
  },
  {
    path: 'settings',
    loadChildren: () => import('./views/settings/settings.routes').then((c) => c.settingsRoutes),
    canActivateChild: [canActivateAuthenticated],
    data: {
      breadcrumb: 'NAVIGATION.WORKSPACE.SETTINGS',
    },
  },
  {
    path: 'profile',
    loadChildren: () => import('./views/profile/profile.routes').then((c) => c.profileRoutes),
    canActivateChild: [canActivateProfile],
    data: {
      breadcrumb: 'NAVIGATION.WORKSPACE.ACCOUNT_MANAGEMENT',
    },
  },
  {
    path: 'producer',
    loadChildren: () => import('./views/producer/producer.routes').then((c) => c.producerRoutes),
    canActivateChild: [canActivateProducer],
    data: {
      breadcrumb: 'NAVIGATION.WORKSPACE.PLUGIN_MANAGEMENT',
    },
  },
  {
    path: 'partner',
    loadChildren: () => import('./views/partner/partner.routes').then((c) => c.partnerRoutes),
    canActivateChild: [canActivateAuthenticated],
    data: {
      breadcrumb: 'NAVIGATION.WORKSPACE.PARTNER_MANAGEMENT',
    },
  },
  {
    path: 'shops',
    loadChildren: () => import('./views/shop-owner/shop-owner.routes').then((c) => c.shopRoutes),
    canActivateChild: [canActivateAuthenticated],
    data: {
      breadcrumb: 'NAVIGATION.WORKSPACE.SHOP_MANAGEMENT',
    },
  },
  {
    path: 'academy',
    loadChildren: () => import('./views/academy/academy.routes').then((c) => c.academyRoutes),
    canActivateChild: [canActivateAuthenticated],
    data: {
      breadcrumb: 'NAVIGATION.WORKSPACE.ACADEMY_MANAGEMENT',
    },
  },
  {
    path: 'teaser',
    loadChildren: () => import('./views/teaser/teaser.routes').then((c) => c.teaserRoutes),
    canActivateChild: [canActivateAuthenticated],
    data: {
      breadcrumb: 'Teaser',
    },
  },
  {
    path: 'terms',
    loadChildren: () =>
      import('./views/terms-and-conditions/terms-and-conditions.routes').then((c) => c.termsAndConditionsRoutes),
    canActivate: [canActivateTerms],
    data: {
      breadcrumb: 'Terms',
    },
  },
  {
    path: 'extension-ratings',
    loadChildren: () =>
      import('./views/extension-ratings/extension-ratings.routes').then((c) => c.extensionRatingsRoutes),
    canActivate: [canActivateAuthenticated],
    data: {},
  },
  {
    path: 'changecompany',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./core/components/company/change-company/change-company.component').then((c) => c.ChangeCompanyComponent),
  },
  {
    path: '404',
    canActivate: [AuthGuard],
    loadComponent: () => import('./core/components/not-found/not-found.component').then((c) => c.NotFoundComponent),
  },
  {
    path: '**',
    canActivate: [AuthGuard],
    loadComponent: () => import('./core/components/not-found/not-found.component').then((c) => c.NotFoundComponent),
  },
];

// @NgModule({
//   imports: [RouterModule.forRoot(routes, {
//     preloadingStrategy: PreloadAllModules,
//     onSameUrlNavigation: 'reload'
//   })],
//   exports: [RouterModule],
//   providers: []
// })
// export class AppRoutingModule {
// }
